import { useForm, Form, FieldButton, OutlinedField } from 'client-shared/hooks/useForm/index.js'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

type UpdateOrganizationDialogForm = {
  name: string
}

export type UpdateOrganizationDialogProps = {
  open: boolean
  organizationId: string
  organizationName: string
  onClose: () => void
  onSubmit: (args: { name: string }) => Promise<unknown>
}

export default function UpdateOrganizationDialog(props: UpdateOrganizationDialogProps) {
  const form = useForm<UpdateOrganizationDialogForm>({ defaultValues: { name: props.organizationName } })

  const onSubmit = ({ name }: UpdateOrganizationDialogForm) => {
    props
      .onSubmit({ name })
      .then(() => props.onClose())
      .then(() => {
        props.onClose()
      })
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xs"
      sx={{ '& .MuiDialog-paper': { m: 0, width: '100%' } }}
      TransitionProps={{ onEnter: () => form.reset({ name: props.organizationName }) }}
    >
      <Form form={form}>
        <DialogTitle>Organizaion編集({props.organizationName})</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
            <OutlinedField name="name" label="Organization名" />
          </Box>
        </DialogContent>
        <DialogActions>
          <FieldButton onClick={props.onClose}>キャンセル</FieldButton>
          <FieldButton variant="contained" onSubmit={onSubmit}>
            編集
          </FieldButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}
