import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

export type RemoveDeviceDialogProps = {
  open: boolean
  onClose: () => void
  onSubmit: (deviceId: string) => Promise<void>
  deviceId: string
  deviceName: string
}

export default function DeleteDeviceDialog(props: RemoveDeviceDialogProps) {
  const onSubmit = () => {
    props
      .onSubmit(props.deviceId)
      .then(() => props.onClose())
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xs"
      sx={{ '& .MuiDialog-paper': { m: 0, width: '100%' } }}
    >
      <DialogTitle>「{props.deviceName}」を削除します</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>ID: {props.deviceId}</Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>キャンセル</Button>
        <Button variant="contained" onClick={onSubmit}>
          削除する
        </Button>
      </DialogActions>
    </Dialog>
  )
}
