import { useState } from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { useOrganizations } from '../../hooks/useOrganizations'
import CreateOrganizationDialog, { CreateOrganizationDialogProps } from './CreateOrganizationDialog'
import UpdateOrganizationDialog, { UpdateOrganizationDialogProps } from './UpdateOrganizationDialog'
import AddOwnerDialog, { AddOwnerDialogProps } from './AddOwnerDialog'

const initCreateOrganizationDialogProps: Omit<CreateOrganizationDialogProps, 'onClose' | 'onSubmit'> = {
  open: false
} as const

const initUpdateOrganizationDialogProps: Omit<UpdateOrganizationDialogProps, 'onClose' | 'onSubmit'> = {
  open: false,
  organizationId: '',
  organizationName: ''
} as const

const initAddOwnerDialogProps: Omit<AddOwnerDialogProps, 'onClose' | 'onSubmit'> = {
  open: false,
  organizationId: '',
  organizationName: ''
} as const

export default function Organizations() {
  const { state, getOrganizations, createOrganization, updateOrganization, addOwners } = useOrganizations()
  const [createOrganizationDialogProps, setCreateOrganizationDialogProps] = useState(initCreateOrganizationDialogProps)
  const [updateOrganizationDialogProps, setUpdateOrganizationDialogProps] = useState(initUpdateOrganizationDialogProps)
  const [addOwnerDialogProps, setAddOwnerDialogProps] = useState(initAddOwnerDialogProps)

  return (
    <Container>
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>名前</TableCell>
              <TableCell>owners</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.ids.map((id) => {
              const organization = state.organizations[id]
              return (
                <TableRow key={id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="td" scope="row">
                    {id}
                  </TableCell>
                  <TableCell scope="row">{organization.name}</TableCell>
                  <TableCell>
                    <List>
                      {organization.ownerUsers.map((owner) => {
                        return (
                          <ListItem key={owner._id}>
                            <ListItemText>{owner.email}</ListItemText>
                          </ListItem>
                        )
                      })}
                    </List>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        setUpdateOrganizationDialogProps({
                          open: true,
                          organizationId: organization._id,
                          organizationName: organization.name
                        })
                      }}
                    >
                      編集
                    </Button>
                    <Button
                      onClick={() => {
                        setAddOwnerDialogProps({
                          open: true,
                          organizationId: organization._id,
                          organizationName: organization.name
                        })
                      }}
                    >
                      owner追加
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box>
        <Button
          onClick={() => {
            setCreateOrganizationDialogProps({ open: true })
          }}
        >
          Organization追加
        </Button>
      </Box>
      <CreateOrganizationDialog
        open={createOrganizationDialogProps.open}
        onClose={() => {
          setCreateOrganizationDialogProps({ ...initCreateOrganizationDialogProps })
        }}
        onSubmit={async ({ name }) => {
          await createOrganization({ name })
          await getOrganizations()
        }}
      />
      <UpdateOrganizationDialog
        {...updateOrganizationDialogProps}
        onClose={() => {
          setUpdateOrganizationDialogProps({ ...initUpdateOrganizationDialogProps })
        }}
        onSubmit={async ({ name }) => {
          await updateOrganization(updateOrganizationDialogProps.organizationId, { name })
          await getOrganizations()
        }}
      />
      <AddOwnerDialog
        {...addOwnerDialogProps}
        onClose={() => {
          setAddOwnerDialogProps({ ...initAddOwnerDialogProps })
        }}
        onSubmit={async (args) => {
          await addOwners(args.organizationId, {
            ownerIds: [args.ownerId]
          })
          await getOrganizations()
        }}
      />
    </Container>
  )
}
