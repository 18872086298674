import { useForm, Form, FieldButton, OutlinedField } from 'client-shared/hooks/useForm/index.js'
import { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { useEditDeliveryDevicesDialog } from '../../hooks/useDelivery'

type FormType = {
  deviceName: string
}

export default function DeliveryDeviceEditDialog() {
  const [tokens, setTokens] = useState<Awaited<ReturnType<typeof getDeliveryDeviceTokens>>['body']['tokens']>([])
  const {
    state,
    closeDialog,
    getDeliveryDeviceTokens,
    createDeliveryDeviceTokens,
    removeDeliveryDeviceToken,
    updateDeliveryDevice
  } = useEditDeliveryDevicesDialog()
  const form = useForm<FormType>({
    defaultValues: { deviceName: state.defaultDeliveryDevicesName }
  })

  useEffect(() => {
    if (form.getValues('deviceName') !== state.defaultDeliveryDevicesName) {
      form.setValue('deviceName', state.defaultDeliveryDevicesName)
    }
  }, [form, state.defaultDeliveryDevicesName])

  useEffect(() => {
    if (!state.deliveryDevicesId) {
      return
    }
    getDeliveryDeviceTokens(state.deliveryDevicesId).then(({ body }) => {
      setTokens(body.tokens)
    })
  }, [state.deliveryDevicesId, getDeliveryDeviceTokens])

  const onCreateToken = () => {
    if (!state.deliveryDevicesId) {
      return
    }
    createDeliveryDeviceTokens(state.deliveryDevicesId)
  }

  const onUpdate = ({ deviceName }: FormType) => {
    // @todo error
    updateDeliveryDevice(state.deliveryDevicesId, { name: deviceName }).then(() => {
      closeDialog()
    })
  }

  const onRemoteToken = (_id: string) => {
    // @todo error
    removeDeliveryDeviceToken(_id).then(() => {
      closeDialog()
    })
  }

  return (
    <Dialog
      open={state.open}
      onClose={() => closeDialog()}
      maxWidth="xs"
      sx={{ '& .MuiDialog-paper': { m: 0, width: '100%' } }}
      TransitionProps={{ onEnter: () => form.reset({}) }}
    >
      <DialogTitle>{state.defaultDeliveryDevicesName}</DialogTitle>
      <DialogContent>
        <Form form={form}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
            <OutlinedField name="deviceName" label="デバイス名" />
            <FieldButton variant="contained" onSubmit={onUpdate}>
              変更
            </FieldButton>
          </Box>
        </Form>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">トークン一覧</Typography>
          <List>
            {tokens.map((token) => {
              return (
                <ListItem
                  key={token._id}
                  alignItems="flex-start"
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => onRemoteToken(token._id)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText>{token.token}</ListItemText>
                </ListItem>
              )
            })}
          </List>
          <Button variant="contained" onClick={onCreateToken}>
            トークン新規作成
          </Button>
        </Box>
        <DialogActions>
          <Button onClick={() => closeDialog()}>閉じる</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
