import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { systemAdminClients } from '../lib/clients'
import { getAPIAccessToken } from '../lib/auth0'
import { flatten } from '../lib/util'

type Device = Awaited<
  ReturnType<(typeof systemAdminClients)['/api/systemAdmin/devices']['GET']['client']>
>['body']['devices'][number]

export type DevicesState = {
  ids: string[]
  devices: Record<string, Device>
}

const devicesState = atom<DevicesState>({
  key: 'systemAdmin:users',
  default: {
    ids: [],
    devices: {}
  }
})

export const useDevices = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const [devices, setDevices] = useRecoilState(devicesState)

  const getDevices = useCallback(async () => {
    const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
    const res = await systemAdminClients['/api/systemAdmin/devices'].GET.client({
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    const { ids, record } = flatten(res.body.devices)
    setDevices({
      ids: ids,
      devices: record
    })

    return res
  }, [getAccessTokenSilently, getAccessTokenWithPopup, setDevices])

  const deleteDevice = useCallback(
    async (deviceId: string) => {
      const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
      const res = await systemAdminClients['/api/systemAdmin/devices/:deviceId'].DELETE.client({
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: { deviceId }
      })

      if (res.ok) {
        await getDevices()
      }
      return res
    },
    [getAccessTokenSilently, getAccessTokenWithPopup, getDevices]
  )

  const updateDevice = useCallback(
    async (deviceId: string, body: Parameters<typeof systemAdminClients['/api/systemAdmin/devices/:deviceId']['PUT']['client']>[0]['body']) => {
      const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
      const res = await systemAdminClients['/api/systemAdmin/devices/:deviceId'].PUT.client({
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: { deviceId },
        body
      })

      if (res.ok) {
        await getDevices()
      }
      return res
    },
    [getAccessTokenSilently, getAccessTokenWithPopup, getDevices]
  )


  return {
    state: devices,
    getDevices,
    deleteDevice,
    updateDevice
  }
}
