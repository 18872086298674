import { useEffect, useState } from 'react'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useActcastGroup } from '../../hooks/useActcastGroup'
import AddActcastGroupDialog, { type AddActcastGroupDialogProps } from './AddActcastGroupDialog'

export default function ActcastGroup() {
  const { state, getActcastGroups, createActcastGroups } = useActcastGroup()
  const [actcastGroupDialogProps, setActcastGroupDialogProps] = useState<Pick<AddActcastGroupDialogProps, 'open'>>({
    open: false
  })

  useEffect(() => {
    getActcastGroups()
  }, [getActcastGroups])

  return (
    <Container>
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>groupId</TableCell>
              <TableCell>token</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.ids.map((id) => {
              const group = state.actcastGroup[id]
              return (
                <TableRow key={group._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {group._id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {group.groupId}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    xxx
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        onClick={() => {
          setActcastGroupDialogProps({
            open: true
          })
        }}
      >
        グループ追加
      </Button>
      <AddActcastGroupDialog
        open={actcastGroupDialogProps.open}
        onClose={() => {
          setActcastGroupDialogProps({
            open: false
          })
        }}
        onSubmit={async (args) => {
          await createActcastGroups(args.groupId, args.token)
          await getActcastGroups()
        }}
      />
    </Container>
  )
}
