import { systemAdminClients } from '../lib/clients'
import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { getAPIAccessToken } from '../lib/auth0'
import { flatten } from '../lib/util.js'

type DeliveryDevice = Awaited<
  ReturnType<(typeof systemAdminClients)['/api/systemAdmin/deliveryDevice']['GET']['client']>
>['body']['deliveryDevices'][number]

type DeliveryDeviceState = {
  ids: string[]
  deliveryDevice: Record<string, DeliveryDevice>
}

const deliveryDeviceState = atom<DeliveryDeviceState>({
  key: 'systemAdmin:deliveryDevice',
  default: {
    ids: [],
    deliveryDevice: {}
  }
})

type EditDeliveryTokenDialog = {
  open: boolean
  deliveryDevicesId: string
  defaultDeliveryDevicesName: string
}

const editDeliveryDeviceDialogState = atom<EditDeliveryTokenDialog>({
  key: 'systemAdmin:editDeliveryDeviceDialog',
  default: {
    open: false,
    deliveryDevicesId: '',
    defaultDeliveryDevicesName: ''
  }
})

export const useEditDeliveryDevicesDialog = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const [deliveryDeviceTokenDialog, setDeliveryDeviceTokenDialogState] = useRecoilState(editDeliveryDeviceDialogState)
  const { getDeliveryDevices } = useDelivery()

  const openDialog = (deliveryDevicesId: string, deliveryDevicesName: string) => {
    setDeliveryDeviceTokenDialogState({
      open: true,
      deliveryDevicesId,
      defaultDeliveryDevicesName: deliveryDevicesName
    })
  }

  const closeDialog = () => {
    setDeliveryDeviceTokenDialogState({
      open: false,
      deliveryDevicesId: '',
      defaultDeliveryDevicesName: ''
    })
  }

  const updateDeliveryDevice = useCallback(
    async (
      deliveryDeviceId: string,
      body: Parameters<
        (typeof systemAdminClients)['/api/systemAdmin/deliveryDevice/:deliveryDeviceId']['PUT']['client']
      >[0]['body']
    ) => {
      const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
      const res = await systemAdminClients['/api/systemAdmin/deliveryDevice/:deliveryDeviceId'].PUT.client({
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: { deliveryDeviceId },
        body
      })

      await getDeliveryDevices()

      return res
    },
    [getAccessTokenSilently, getAccessTokenWithPopup, getDeliveryDevices]
  )

  const createDeliveryDeviceToken = useCallback(
    async (deliveryDeviceId: string) => {
      const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
      const res = await systemAdminClients['/api/systemAdmin/deliveryDevice/:deliveryDeviceId/token'].POST.client({
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: { deliveryDeviceId }
      })
      return res
    },
    [getAccessTokenSilently, getAccessTokenWithPopup]
  )

  const getDeliveryDeviceTokens = useCallback(
    async (deliveryDeviceId: string) => {
      const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
      const res = await systemAdminClients['/api/systemAdmin/deliveryDevice/:deliveryDeviceId/token'].GET.client({
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: { deliveryDeviceId }
      })
      return res
    },
    [getAccessTokenSilently, getAccessTokenWithPopup]
  )

  const removeDeliveryDeviceToken = useCallback(
    async (tokenId: string) => {
      const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
      const res = await systemAdminClients['/api/systemAdmin/deliveryDevice/token/:tokenId'].DELETE.client({
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: { tokenId }
      })
      return res
    },
    [getAccessTokenSilently, getAccessTokenWithPopup]
  )
  return {
    state: deliveryDeviceTokenDialog,
    openDialog,
    closeDialog,
    createDeliveryDeviceTokens: createDeliveryDeviceToken,
    getDeliveryDeviceTokens,
    removeDeliveryDeviceToken,
    updateDeliveryDevice
  } as const
}

type DeliveryDeviceDialog = {
  open: boolean
}

const deliveryDeviceDialogState = atom<DeliveryDeviceDialog>({
  key: 'systemAdmin:deliveryDeviceDialog',
  default: {
    open: false
  }
})

export const useDeliveryDeviceDialog = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const [deliveryDeviceDialog, setDeliveryDeviceDialogState] = useRecoilState(deliveryDeviceDialogState)

  const openDialog = () => {
    setDeliveryDeviceDialogState({ open: true })
  }

  const closeDialog = () => {
    setDeliveryDeviceDialogState({ open: false })
  }

  const getDeliveryDevices = useCallback(async () => {
    const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
    return await systemAdminClients['/api/systemAdmin/deliveryDevice'].GET.client({
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }, [getAccessTokenSilently, getAccessTokenWithPopup])

  const createDeliveryDevices = useCallback(
    async (name: string) => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
      return await systemAdminClients['/api/systemAdmin/deliveryDevice'].POST.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: { name }
      })
    },
    [getAccessTokenSilently, getAccessTokenWithPopup]
  )

  return {
    state: deliveryDeviceDialog,
    openDialog,
    closeDialog,
    createDeliveryDevices,
    getDeliveryDevices
  } as const
}

export const useDelivery = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const [deliveryDevice, setDeliveryDevice] = useRecoilState(deliveryDeviceState)

  const getDeliveryDevices = useCallback(async () => {
    const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
    const res = await systemAdminClients['/api/systemAdmin/deliveryDevice'].GET.client({
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    const { ids, record } = flatten(res.body.deliveryDevices)
    setDeliveryDevice({
      ids,
      deliveryDevice: record
    })

    return res
  }, [getAccessTokenSilently, getAccessTokenWithPopup, setDeliveryDevice])

  return {
    state: deliveryDevice,
    getDeliveryDevices
  } as const
}
