import { NavLink } from 'react-router-dom'
import { useTheme } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import CircleIcon from '@mui/icons-material/Circle'

export default function MenuList() {
  return (
    <List component="nav">
      <Item to="/" text="organizations" />
      <Item to="/users" text="users" />
      <Item to="/actcastGroups" text="actcastGroups" />
      <Item to="/devices" text="devices" />
      <Item to="/actcastDeviceGroups" text="actcastDeviceGroups" />
      <Item to="/delivery" text="delivery" />
      <Item to="/apiToken" text="apiToken" />
    </List>
  )
}

function Item(props: { to: string; text: string }) {
  const theme = useTheme()

  return (
    <ListItem disablePadding>
      <NavLink
        to={props.to}
        style={{
          width: '100%',
          textDecoration: 'none',
          color: theme.palette.primary.main
        }}
      >
        {({ isActive }) => {
          return (
            <ListItemButton selected={isActive}>
              <ListItemIcon
                sx={{
                  minWidth: 30,
                  ml: 1
                }}
              >
                <CircleIcon sx={{ fontSize: 10 }} />
              </ListItemIcon>
              <ListItemText primary={props.text} />
            </ListItemButton>
          )
        }}
      </NavLink>
    </ListItem>
  )
}
