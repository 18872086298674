import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import Box from '@mui/material/Box'
import { useMyProfile } from './hooks/useMyProfile'
import Loading from './components/Loading'
import { LogoutButton } from './components/Logout'
import Layout from './components/Layout'
import Login from './components/Login'
import Organizations from './pages/organizations'
import ActcastDeviceGroups from './pages/actcastDeviceGroups'
import Delivery from './pages/delivery'
import Users from './pages/users'
import Devices from './pages/devices'
import ActcastGroups from './pages/actcastGroup'
import ApiToken from './pages/apiToken'

export default function App() {
  const { isLoading, isAuthenticated, user } = useAuth0()
  const { loading, isSystemAdmin } = useMyProfile()

  if (isLoading) {
    return <Loading />
  }

  if (!isAuthenticated) {
    return <Login />
  }

  if (loading) {
    return (
      <Box>
        <div>{user.email}</div>
        <Loading />
      </Box>
    )
  }

  if (!isSystemAdmin) {
    return (
      <Box>
        <div>{user.email}</div>
        <LogoutButton />
      </Box>
    )
  }

  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Organizations />} />
          <Route path="actcastDeviceGroups" element={<ActcastDeviceGroups />} />
          <Route path="users" element={<Users />} />
          <Route path="devices" element={<Devices />} />
          <Route path="delivery" element={<Delivery />} />
          <Route path="actcastGroups" element={<ActcastGroups />} />
          <Route path="apiToken" element={<ApiToken />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </Router>
  )
}
