import { useEffect } from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Buttom from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useDelivery, useEditDeliveryDevicesDialog, useDeliveryDeviceDialog } from '../../hooks/useDelivery'
import DeliveryDeviceDialog from '../../components/Dialog/DeliveryDeviceDialog'
import DeliveryDeviceEditDialog from '../../components/Dialog/DeliveryDeviceEditDialog'

export default function Delivery() {
  const { state, getDeliveryDevices } = useDelivery()
  const { openDialog: openEditDialog } = useEditDeliveryDevicesDialog()
  const { openDialog: openDeliveryDeviceDialog } = useDeliveryDeviceDialog()

  useEffect(() => {
    getDeliveryDevices()
  }, [getDeliveryDevices])

  return (
    <Container>
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>名前</TableCell>
              <TableCell>修正</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.ids.map((id) => {
              const device = state.deliveryDevice[id]
              return (
                <TableRow key={device._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {device._id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {device.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <IconButton onClick={() => openEditDialog(device._id, device.name)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ p: 2 }}>
        <Buttom variant="contained" onClick={() => openDeliveryDeviceDialog()}>
          トラッキングデバイス作成
        </Buttom>
      </Box>
      <DeliveryDeviceDialog />
      <DeliveryDeviceEditDialog />
    </Container>
  )
}
