import { useEffect, useState } from 'react'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useApiTokens } from '../../hooks/useApiTokens'
import AddDialog, { type AddDialogProps } from './AddDialog'

const initAddDialogProps: Omit<AddDialogProps, 'onClose' | 'onSubmit'> = {
  open: false
} as const

export default function ApiToken() {
  const { state: apiTokens, getApiTokens, addApiToken } = useApiTokens()
  const [addDialogProps, setAddDialogProps] = useState(initAddDialogProps)

  useEffect(() => {
    getApiTokens()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>token</TableCell>
              <TableCell>organizationId</TableCell>
              <TableCell>userId</TableCell>
              <TableCell>memo</TableCell>
              <TableCell>createdAt</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apiTokens.ids.map((id) => {
              const token = apiTokens.apiTokens[id]
              return (
                <TableRow key={id}>
                  <TableCell>{id}</TableCell>
                  <TableCell>{token.token}</TableCell>
                  <TableCell>{token.organizationId}</TableCell>
                  <TableCell>{token.userId}</TableCell>
                  <TableCell>{token.memo}</TableCell>
                  <TableCell>{token.createdAt}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        onClick={() => {
          setAddDialogProps({ open: true })
        }}
      >
        token追加
      </Button>
      <AddDialog
        {...addDialogProps}
        onClose={() => setAddDialogProps({ ...initAddDialogProps, open: false })}
        onSubmit={async (args) => {
          await addApiToken({ body: args })
        }}
      />
    </Container>
  )
}
