import { useState, type MouseEvent } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import MuiAppBar, { AppBarProps } from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { LogoutLink } from '../Logout'

type Props = {
  open: boolean
  drawerWidth: number
} & AppBarProps

export default function MainHeader({ open, drawerWidth }: Props) {
  const { user } = useAuth0()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <AppBar position="static" drawerWidth={drawerWidth} open={open}>
        <Toolbar>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2} sx={{ width: '100%' }}>
            <Item>{user.email}</Item>
            <Item>
              <IconButton sx={{ m: 0, p: 0 }} size="small" onClick={handleClick}>
                <Avatar src={user.picture}>{user.nickname}</Avatar>
              </IconButton>
            </Item>
          </Stack>
        </Toolbar>
      </AppBar>
      <Menu anchorEl={anchorEl} open={anchorEl !== null} onClose={handleClose} onClick={handleClose}>
        <MenuItem>
          <LogoutLink />
        </MenuItem>
      </Menu>
    </>
  )
}

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth'
})<Props>(({ theme, open, drawerWidth }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))
