import ReactDOM from 'react-dom/client'
import CssBaseline from '@mui/material/CssBaseline'
import ThemeProvider from '../ThemeProvider'
import { Auth0Provider } from '@auth0/auth0-react'
import { RecoilRoot } from 'recoil'
import { auth0 } from './constants'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <RecoilRoot>
    <Auth0Provider domain={auth0.domain} clientId={auth0.clientId} authorizationParams={auth0.authorizationParams}>
      <ThemeProvider>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Auth0Provider>
  </RecoilRoot>
)
