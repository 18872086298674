import { useForm, Form, FieldButton, OutlinedField } from 'client-shared/hooks/useForm/index.js'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { useDeliveryDeviceDialog } from '../../hooks/useDelivery'

type FormType = {
  name: string
}

const defaultValues: FormType = {
  name: ''
} as const

export default function DeliveryDevicesDialog() {
  const { state, closeDialog, createDeliveryDevices, getDeliveryDevices } = useDeliveryDeviceDialog()
  const form = useForm({ defaultValues: { ...defaultValues } })

  const onSubmit = ({ name }: FormType) => {
    if (name) {
      createDeliveryDevices(name).then(() => {
        closeDialog()
        getDeliveryDevices()
      })
    }
  }

  return (
    <Dialog
      open={state.open}
      onClose={() => closeDialog()}
      maxWidth="xs"
      sx={{ '& .MuiDialog-paper': { m: 0, width: '100%' } }}
      TransitionProps={{ onEnter: () => form.reset({}) }}
    >
      <Form form={form}>
        <DialogTitle>トラッキングデバイス作成</DialogTitle>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
          <OutlinedField name="name" label="デバイス名入力" />
        </Box>
        <DialogContent></DialogContent>
        <DialogActions>
          <FieldButton onClick={() => closeDialog()}>閉じる</FieldButton>
          <FieldButton onSubmit={onSubmit}>作成</FieldButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}
