import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

export type RemoveUserDialogProps = {
  userId: string
  userName: string
  deviceGroupId: string
  deviceGroupName: string
  open: boolean
  onClose: () => void
  onSubmit: (args: { userId: string }) => Promise<void>
}

export default function RemoveUserDialog(props: RemoveUserDialogProps) {
  const onSubmit = () => {
    props.onSubmit({ userId: props.userId }).then(() => props.onClose())
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xs"
      sx={{ '& .MuiDialog-paper': { m: 0, width: '100%' } }}
    >
      <DialogTitle>ユーザー削除</DialogTitle>
      <DialogContent sx={{ paddingTop: 0, paddingBottom: 1, paddingLeft: 3, paddingRight: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {props.deviceGroupName}から{props.userName}({props.userId})を削除します
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>キャンセル</Button>
        <Button variant="contained" onClick={onSubmit}>
          削除する
        </Button>
      </DialogActions>
    </Dialog>
  )
}
