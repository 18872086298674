import { useForm, Form, FieldButton, OutlinedField } from 'client-shared/hooks/useForm/index.js'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

type UpdateGroupDialogForm = {
  groupName: string
}

type SubmitArgs = {
  name?: string
}

export type UpdateGroupDialogProps = {
  open: boolean
  onClose: () => void
  deviceGroupId: string
  deviceGroupName: string
  onSubmit: (args: SubmitArgs) => Promise<unknown>
}

export default function UpdateGroupDialog(props: UpdateGroupDialogProps) {
  const form = useForm<UpdateGroupDialogForm>({
    defaultValues: {
      groupName: props.deviceGroupName
    }
  })

  const onSubmit = ({ groupName }: UpdateGroupDialogForm) => {
    const args: SubmitArgs = {}
    if (groupName) {
      args.name = groupName
    }
    props.onSubmit(args).then(() => props.onClose())
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xs"
      sx={{ '& .MuiDialog-paper': { m: 0, width: '100%' } }}
      TransitionProps={{ onEnter: () => form.reset({ groupName: props.deviceGroupName }) }}
    >
      <Form form={form}>
        <DialogTitle>グループ編集({props.deviceGroupId})</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
            <OutlinedField name="groupName" label="グループ名" />
          </Box>
        </DialogContent>
        <DialogActions>
          <FieldButton onClick={props.onClose}>キャンセル</FieldButton>
          <FieldButton variant="contained" onSubmit={onSubmit}>
            ActCast Group編集
          </FieldButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}
