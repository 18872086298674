import { useForm, Form, FieldButton, OutlinedField } from 'client-shared/hooks/useForm/index.js'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

type AddGroupDialogForm = {
  groupName: string
}

export type AddGroupDialogProps = {
  open: boolean
  onClose: () => void
  organizationId: string
  onSubmit: (args: { name: string }) => Promise<void>
}

export default function AddGroupDialog(props: AddGroupDialogProps) {
  const form = useForm<AddGroupDialogForm>({ defaultValues: { groupName: '' } })

  const onSubmit = (args: AddGroupDialogForm) => {
    props.onSubmit({ name: args.groupName }).then(() => {
      props.onClose()
    })
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xs"
      sx={{ '& .MuiDialog-paper': { m: 0, width: '100%' } }}
      TransitionProps={{ onEnter: () => form.reset({ groupName: '' }) }}
    >
      <Form form={form}>
        <DialogTitle>グループ追加</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
            <OutlinedField name="groupName" label="グループ名" />
          </Box>
        </DialogContent>
        <DialogActions>
          <FieldButton onClick={props.onClose}>キャンセル</FieldButton>
          <FieldButton variant="contained" onSubmit={onSubmit}>
            ActCast Group作成
          </FieldButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}
