import type { ReactNode } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { useActcastDeviceGroup, type ActcastDeviceGroupState } from '../../hooks/useActcastDeviceGroup'

type Props = {
  onRemoveDevice: DeviceTableRowProps['onRemoveDevice']
  onRemoveUser: DeviceTableRowProps['onRemoveUser']
  buttons?: (props: { group: ActcastDeviceGroupState['actcastDeviceGroup'][string] }) => ReactNode
}

export default function DeviceTable(props: Props) {
  const { state } = useActcastDeviceGroup()

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>名前</TableCell>
            <TableCell>デバイス一覧</TableCell>
            <TableCell>ユーザー一覧</TableCell>
            <TableCell>通知先</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.ids.length <= 0 && (
            <TableRow>
              <TableCell component="th" scope="row">
                デバイスグループがありません
              </TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>
            </TableRow>
          )}
          {state.ids.length > 0 &&
            state.ids.map((id) => {
              const group = state.actcastDeviceGroup[id]
              return (
                <DeviceTableRow
                  key={group._id}
                  group={group}
                  buttons={props.buttons ? props.buttons({ group }) : null}
                  onRemoveDevice={props.onRemoveDevice}
                  onRemoveUser={props.onRemoveUser}
                />
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

type DeviceTableRowProps = {
  group: ActcastDeviceGroupState['actcastDeviceGroup'][string]
  buttons?: ReactNode
  onRemoveDevice: (arg: {
    deviceId: string
    deviceName: string
    deviceGroupId: string
    deviceGroupName: string
  }) => void
  onRemoveUser: (arg: { userId: string; userName: string; deviceGroupId: string; deviceGroupName: string }) => void
}

function DeviceTableRow(props: DeviceTableRowProps) {
  const { group } = props

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        {group._id}
      </TableCell>
      <TableCell component="th" scope="row">
        {group.name}
      </TableCell>
      <TableCell component="th" scope="row">
        <ul>
          {group.groupDevices.map((device) => {
            return (
              <li key={device._id} style={{ display: 'flex', alignItems: 'center' }}>
                {device.name}
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    props.onRemoveDevice({
                      deviceId: device._id,
                      deviceName: device.name,
                      deviceGroupId: group._id,
                      deviceGroupName: group.name
                    })
                  }}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </li>
            )
          })}
        </ul>
      </TableCell>
      <TableCell component="th" scope="row">
        <ul>
          {group.groupUsers.map((user) => {
            return (
              <li key={user._id} style={{ display: 'flex', alignItems: 'center' }}>
                {user.email}
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    props.onRemoveUser({
                      userId: user._id,
                      userName: user.email,
                      deviceGroupId: group._id,
                      deviceGroupName: group.name
                    })
                  }}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </li>
            )
          })}
        </ul>
      </TableCell>
      <TableCell component="th" scope="row">
        {props.buttons}
      </TableCell>
    </TableRow>
  )
}
