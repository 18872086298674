import { useState } from 'react'
import { atom, useRecoilState } from 'recoil'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useActcastDeviceGroup } from '../../hooks/useActcastDeviceGroup'
import { useOrganizations } from '../../hooks/useOrganizations'
import UpdateGroupDialog, { type UpdateGroupDialogProps } from './UpdateGroupDialog'
import AddGroupDialog, { type AddGroupDialogProps } from './AddGroupDialog'
import RemoveDeviceDialog, { type RemoveDeviceDialogProps } from './RemoveDeviceDialog'
import AddDeviceDialog, { type AddDeviceDialogProps } from './AddDeviceDialog'
import RemoveUserDialog, { type RemoveUserDialogProps } from './RemoveUserDialog'
import AddUserDialog, { type AddUserDialogProps } from './AddUserDialog'
import Loading from '../../components/Loading'
import DeviceTable from './DeviceTable'

const initaAddUserDialogProps: Omit<AddUserDialogProps, 'onClose' | 'onSubmit'> = {
  open: false,
  deviceGroupId: ''
} as const

const initAddDeviceDialogProps: Omit<AddDeviceDialogProps, 'onClose' | 'onSubmit'> = {
  open: false,
  deviceGroupId: ''
} as const

const initRemoveDeviceDialogProps: Omit<RemoveDeviceDialogProps, 'onClose' | 'onSubmit'> = {
  open: false,
  deviceGroupId: '',
  deviceGroupName: '',
  deviceId: '',
  deviceName: ''
} as const

const initAddGroupDialogProps: Omit<AddGroupDialogProps, 'onClose' | 'onSubmit'> = {
  open: false,
  organizationId: ''
} as const

const initUpdateGroupDialogProps: Omit<UpdateGroupDialogProps, 'onClose' | 'onSubmit'> = {
  open: false,
  deviceGroupId: '',
  deviceGroupName: ''
} as const

const initRemoveUserDialogProps: Omit<RemoveUserDialogProps, 'onClose' | 'onSubmit'> = {
  open: false,
  userId: '',
  userName: '',
  deviceGroupId: '',
  deviceGroupName: ''
} as const

type PageState = {
  selectedOrganizationId: string
}

const pageState = atom<PageState>({
  key: 'systemAdmin:page:ActcastDeviceGroups',
  default: {
    selectedOrganizationId: ''
  }
})

export default function ActcastDeviceGroups() {
  const [page, setPage] = useRecoilState(pageState)
  const [addUserDialogProps, setAddUserDialogProps] = useState(initaAddUserDialogProps)
  const [addDeviceDialogProps, setAddDeviceDialogProps] = useState(initAddDeviceDialogProps)
  const [removeDeviceDialogProps, setRemoveDeviceDialogProps] = useState(initRemoveDeviceDialogProps)
  const [addGroupDialogProps, setAddGroupDeialogProps] = useState(initAddGroupDialogProps)
  const [updateGroupDialogProps, setUpdateGroupDialogProps] = useState(initUpdateGroupDialogProps)
  const [removeUserDialogProps, setRemoveUserDialogProps] = useState(initRemoveUserDialogProps)
  const { state: organizations } = useOrganizations()
  const {
    state,
    getActcastDeviceGroups,
    addUserToGroup,
    removeUserFromGroup,
    addActcastDeviceToGroup,
    addActcastDeviceGroup,
    updateActcastDeviceGroup,
    removeActcastDeviceFromGroup
  } = useActcastDeviceGroup()

  const handleChangeOrgId = (e: SelectChangeEvent) => {
    setPage({
      selectedOrganizationId: e.target.value
    })
    getActcastDeviceGroups(e.target.value)
  }

  const menuItems = organizations.ids.map((id) => {
    const org = organizations.organizations[id]
    return <MenuItem key={org._id} value={org._id}>{`${org.name}(${org._id})`}</MenuItem>
  })

  return (
    <Container>
      <FormControl fullWidth size="small" sx={{ mt: 3 }}>
        <Select onChange={handleChangeOrgId} value={page.selectedOrganizationId}>
          {menuItems}
        </Select>
      </FormControl>
      {state.loadingGetActcastDeviceGroup && <Loading />}
      <DeviceTable
        buttons={(props) => {
          return (
            <>
              <Button
                onClick={() => {
                  setUpdateGroupDialogProps({
                    open: true,
                    deviceGroupId: props.group._id,
                    deviceGroupName: props.group.name
                  })
                }}
              >
                編集
              </Button>
              <Button
                onClick={() => {
                  setAddUserDialogProps({
                    open: true,
                    deviceGroupId: props.group._id
                  })
                }}
              >
                ユーザー追加
              </Button>
              <Button
                onClick={() => {
                  setAddDeviceDialogProps({
                    open: true,
                    deviceGroupId: props.group._id
                  })
                }}
              >
                デバイス追加
              </Button>
            </>
          )
        }}
        onRemoveDevice={(args) => {
          setRemoveDeviceDialogProps({ ...args, open: true })
        }}
        onRemoveUser={(args) => {
          setRemoveUserDialogProps({ ...args, open: true })
        }}
      />
      <Button
        onClick={() => {
          setAddGroupDeialogProps({
            open: true,
            organizationId: page.selectedOrganizationId
          })
        }}
      >
        デバイスグループ追加
      </Button>
      <AddUserDialog
        deviceGroupId={addUserDialogProps.deviceGroupId}
        open={addUserDialogProps.open}
        onClose={() => {
          setAddUserDialogProps({ open: false, deviceGroupId: '' })
        }}
        onSubmit={async ({ userId }) => {
          await addUserToGroup(addUserDialogProps.deviceGroupId, { userId })
          await getActcastDeviceGroups(page.selectedOrganizationId)
        }}
      />
      <RemoveUserDialog
        {...removeUserDialogProps}
        onClose={() => {
          setRemoveUserDialogProps({ ...initRemoveUserDialogProps })
        }}
        onSubmit={async () => {
          await removeUserFromGroup(removeUserDialogProps.deviceGroupId, { userId: removeUserDialogProps.userId })
          await getActcastDeviceGroups(page.selectedOrganizationId)
        }}
      />
      <AddDeviceDialog
        {...addDeviceDialogProps}
        onClose={() => {
          setAddDeviceDialogProps({ ...initAddDeviceDialogProps })
        }}
        onSubmit={async ({ deviceId }) => {
          await addActcastDeviceToGroup(addDeviceDialogProps.deviceGroupId, { deviceId })
          await getActcastDeviceGroups(page.selectedOrganizationId)
        }}
      />
      <RemoveDeviceDialog
        {...removeDeviceDialogProps}
        onClose={() => {
          setRemoveDeviceDialogProps({ ...initRemoveDeviceDialogProps })
        }}
        onSubmit={async () => {
          await removeActcastDeviceFromGroup(removeDeviceDialogProps.deviceGroupId, {
            deviceId: removeDeviceDialogProps.deviceId
          })
          await getActcastDeviceGroups(page.selectedOrganizationId)
        }}
      />
      <AddGroupDialog
        {...addGroupDialogProps}
        onClose={() => setAddGroupDeialogProps({ ...initAddGroupDialogProps })}
        onSubmit={async (args) => {
          await addActcastDeviceGroup(addGroupDialogProps.organizationId, args)
          await getActcastDeviceGroups(addGroupDialogProps.organizationId)
        }}
      />
      <UpdateGroupDialog
        {...updateGroupDialogProps}
        onClose={() => setUpdateGroupDialogProps({ ...initUpdateGroupDialogProps })}
        onSubmit={async (args) => {
          updateActcastDeviceGroup(updateGroupDialogProps.deviceGroupId, args)
        }}
      />
    </Container>
  )
}
