export const flatten = <T extends { _id: string }>(arr: T[]) => {
  const ids: string[] = []
  const record: Record<string, T> = {}

  for (const doc of arr) {
    ids.push(doc._id)
    record[doc._id] = doc
  }

  return {
    ids,
    record
  }
}
