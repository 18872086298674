import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

import { useAuth0 } from '@auth0/auth0-react'

export default function Login() {
  const { loginWithRedirect } = useAuth0()

  return (
    <Container>
      <Paper sx={{ p: 3 }}>
        <Stack justifyContent="center">
          <Toolbar>
            <Box
              sx={{
                color: 'text.primary',
                textDecoration: 'none',
                fontWeight: 'bold',
                fontFamily: 'Noto Sans JP'
              }}
            >
              VISNU CLOUD ADMIN
            </Box>
          </Toolbar>
          <Button size="large" variant="contained" onClick={() => loginWithRedirect()}>
            ログイン
          </Button>
        </Stack>
      </Paper>
    </Container>
  )
}
