import { Outlet, NavLink } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Paper from '@mui/material/Paper'
import Toolbar from '@mui/material/Toolbar'
import Divider from '@mui/material/Divider'
import MainHeader from './MainHeader'
import MenuList from './MenuList'

const drawerWidth = 258
const open = true

const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth'
})<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: drawerWidth
  })
}))

export default function SystemAdminLayout() {
  return (
    <Box sx={{ display: 'felx' }}>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}
        anchor="left"
        open={open}
      >
        <Paper>
          <Toolbar>
            <Box
              component={NavLink}
              to="/"
              sx={{
                color: 'text.primary',
                textDecoration: 'none',
                fontWeight: 'bold',
                fontFamily: 'Noto Sans JP'
              }}
            >
              VISNU CLOUD ADMIN
            </Box>
          </Toolbar>
          <Divider />
          <Box style={{ width: drawerWidth }}>
            <MenuList />
          </Box>
        </Paper>
      </Drawer>
      <MainHeader open={true} drawerWidth={drawerWidth} />
      <Main open={true}>
        <Outlet />
      </Main>
    </Box>
  )
}
