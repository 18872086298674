import { systemAdminClients } from '../lib/clients'
import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { getAPIAccessToken } from '../lib/auth0'
import { flatten } from '../lib/util.js'

type ApiToken = Awaited<
  ReturnType<(typeof systemAdminClients)['/api/systemAdmin/apiToken']['GET']['client']>
>['body']['tokens'][number]

type ApiTokenState = {
  ids: string[]
  apiTokens: Record<string, ApiToken>
}

const apiTokenState = atom<ApiTokenState>({
  key: 'systemAdmin:apitoken',
  default: {
    ids: [],
    apiTokens: {}
  }
})

export const useApiTokens = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const [apiTokens, setApiTokens] = useRecoilState(apiTokenState)

  const getApiTokens = useCallback(async () => {
    const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
    const res = await systemAdminClients['/api/systemAdmin/apiToken'].GET.client({
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    const { ids, record } = flatten(res.body.tokens)
    setApiTokens({
      ids,
      apiTokens: record
    })

    return res
  }, [getAccessTokenSilently, getAccessTokenWithPopup, setApiTokens])

  const addApiToken = useCallback(
    async (args: {
      body: Parameters<(typeof systemAdminClients)['/api/systemAdmin/apiToken']['POST']['client']>[0]['body']
    }) => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
      const res = await systemAdminClients['/api/systemAdmin/apiToken'].POST.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: args.body
      })

      return res
    },
    [getAccessTokenSilently, getAccessTokenWithPopup]
  )

  return {
    state: apiTokens,
    getApiTokens,
    addApiToken
  } as const
}
