import type { AuthorizationParams } from '@auth0/auth0-react'
const devFlag = process.env.NODE_ENV !== 'production'

export const ENV = {
  dev: devFlag
} as const

export const HOST = {
  API: devFlag ? 'http://localhost:8080' : 'https://visnu-cloud-api.visnu.io'
} as const

export const auth0 = {
  domain: 'visnu.jp.auth0.com',
  clientId: devFlag ? 'uS46uosoAzkWyVNCoMYaBbxwFJBIblS5' : 'e5tHKuKvbsMoGijD1FV3fyhRpvkgl0Nb',
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: 'https://visnu.jp.auth0.com/api/v2/'
  } satisfies AuthorizationParams
} as const
