import { systemAdminClients } from '../lib/clients'
import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { getAPIAccessToken } from '../lib/auth0'
import { flatten } from '../lib/util.js'

type ActcastDeviceGroup = Awaited<
  ReturnType<
    (typeof systemAdminClients)['/api/systemAdmin/organizations/:organizationId/actcastDeviceGroups']['GET']['client']
  >
>['body']['actcastDeviceGroups'][number]

export type ActcastDeviceGroupState = {
  ids: string[]
  actcastDeviceGroup: Record<string, ActcastDeviceGroup>
  loadingGetActcastDeviceGroup: boolean
}

const actcastDeviceGroupState = atom<ActcastDeviceGroupState>({
  key: 'systemAdmin:actcastDeviceGroup',
  default: {
    ids: [],
    actcastDeviceGroup: {},
    loadingGetActcastDeviceGroup: false
  }
})

export const useActcastDeviceGroup = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const [actcastDeviceGroup, setActcastDeviceGroup] = useRecoilState(actcastDeviceGroupState)

  const getActcastDeviceGroups = useCallback(
    async (organizationId: string) => {
      const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
      setActcastDeviceGroup((prev) => {
        return {
          ...prev,
          loadingGetActcastDeviceGroup: true
        }
      })

      const res = await systemAdminClients[
        '/api/systemAdmin/organizations/:organizationId/actcastDeviceGroups'
      ].GET.client({
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: { organizationId }
      })

      const { ids, record } = flatten(res.body.actcastDeviceGroups)
      setActcastDeviceGroup((prev) => {
        return {
          ...prev,
          ids,
          actcastDeviceGroup: record,
          loadingGetActcastDeviceGroup: false
        }
      })

      return res
    },
    [getAccessTokenSilently, getAccessTokenWithPopup, setActcastDeviceGroup]
  )

  const addActcastDeviceGroup = useCallback(
    async (
      organizationId: string,
      body: Parameters<
        (typeof systemAdminClients)['/api/systemAdmin/organizations/:organizationId/actcastDeviceGroups']['POST']['client']
      >[0]['body']
    ) => {
      const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
      const res = await systemAdminClients[
        '/api/systemAdmin/organizations/:organizationId/actcastDeviceGroups'
      ].POST.client({
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: { organizationId },
        body
      })

      return res
    },
    [getAccessTokenSilently, getAccessTokenWithPopup]
  )

  const updateActcastDeviceGroup = useCallback(
    async (
      deviceGroupId: string,
      body: Parameters<
        (typeof systemAdminClients)['/api/systemAdmin/actcastDeviceGroups/:deviceGroupId']['PUT']['client']
      >[0]['body']
    ) => {
      const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
      const res = await systemAdminClients['/api/systemAdmin/actcastDeviceGroups/:deviceGroupId'].PUT.client({
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: { deviceGroupId },
        body
      })

      return res
    },
    [getAccessTokenSilently, getAccessTokenWithPopup]
  )

  const addActcastDeviceToGroup = useCallback(
    async (
      deviceGroupId: string,
      body: Parameters<
        (typeof systemAdminClients)['/api/systemAdmin/actcastDeviceGroups/:deviceGroupId/devices']['POST']['client']
      >[0]['body']
    ) => {
      const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
      const res = await systemAdminClients['/api/systemAdmin/actcastDeviceGroups/:deviceGroupId/devices'].POST.client({
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: { deviceGroupId },
        body
      })

      return res
    },
    [getAccessTokenSilently, getAccessTokenWithPopup]
  )

  const removeActcastDeviceFromGroup = useCallback(
    async (
      deviceGroupId: string,
      body: Parameters<
        (typeof systemAdminClients)['/api/systemAdmin/actcastDeviceGroups/:deviceGroupId/devices']['DELETE']['client']
      >[0]['body']
    ) => {
      const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
      const res = await systemAdminClients['/api/systemAdmin/actcastDeviceGroups/:deviceGroupId/devices'].DELETE.client(
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          params: { deviceGroupId },
          body
        }
      )

      return res
    },
    [getAccessTokenSilently, getAccessTokenWithPopup]
  )

  const addUserToGroup = useCallback(
    async (
      deviceGroupId: string,
      body: Parameters<
        (typeof systemAdminClients)['/api/systemAdmin/actcastDeviceGroups/:deviceGroupId/users']['POST']['client']
      >[0]['body']
    ) => {
      const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
      const res = await systemAdminClients['/api/systemAdmin/actcastDeviceGroups/:deviceGroupId/users'].POST.client({
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: { deviceGroupId },
        body
      })

      return res
    },
    [getAccessTokenSilently, getAccessTokenWithPopup]
  )

  const removeUserFromGroup = useCallback(
    async (
      deviceGroupId: string,
      body: Parameters<
        (typeof systemAdminClients)['/api/systemAdmin/actcastDeviceGroups/:deviceGroupId/users']['DELETE']['client']
      >[0]['body']
    ) => {
      const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
      const res = await systemAdminClients['/api/systemAdmin/actcastDeviceGroups/:deviceGroupId/users'].DELETE.client({
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: { deviceGroupId },
        body
      })

      return res
    },
    [getAccessTokenSilently, getAccessTokenWithPopup]
  )

  return {
    state: actcastDeviceGroup,
    getActcastDeviceGroups,
    addActcastDeviceGroup,
    updateActcastDeviceGroup,
    addActcastDeviceToGroup,
    addUserToGroup,
    removeUserFromGroup,
    removeActcastDeviceFromGroup
  }
}
