import { useForm, Form, FieldButton, OutlinedField } from 'client-shared/hooks/useForm/index.js'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

export type AddDialogProps = {
  open: boolean
  onClose: () => void
  onSubmit: (args: { organizationId: string; memo: string }) => Promise<unknown>
}

type AddForm = {
  organizationId: string
  memo: string
}

export default function AddDialog(props: AddDialogProps) {
  const form = useForm<AddForm>({ defaultValues: { organizationId: '', memo: '' } })

  const onSubmit = ({ organizationId, memo }: AddForm) => {
    props.onSubmit({ organizationId, memo }).then(() => props.onClose())
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xs"
      sx={{ '& .MuiDialog-paper': { m: 0, width: '100%' } }}
      TransitionProps={{ onEnter: () => form.reset({ organizationId: '', memo: '' }) }}
    >
      <Form form={form}>
        <DialogTitle>グループ追加</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
            <OutlinedField name="organizationId" label="organizationId" />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
            <OutlinedField name="memo" label="memo" />
          </Box>
        </DialogContent>
        <DialogActions>
          <FieldButton onClick={props.onClose}>キャンセル</FieldButton>
          <FieldButton variant="contained" onSubmit={onSubmit}>
            追加する
          </FieldButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}
