import React from 'react'
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import locale from 'date-fns/locale/ja'

export default function ThemeProvider(props: { children: React.ReactNode }) {
  const mode = 'dark'

  const theme = createTheme({
    palette: {
      mode,
      secondary: { main: '#556677' },
      background:
        mode === 'dark'
          ? {
              default: '#2a2a2a'
            }
          : {
              default: '#eeeeee'
            }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        #root {
          min-height: 100vh;
          display: flex;
          flex-direction: column;
        }
      `
      }
    }
  })

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        {props.children}
      </LocalizationProvider>
    </MuiThemeProvider>
  )
}
